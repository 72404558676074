import React, { useState } from 'react'
import {
  Row,
  Col,
  Button,
  message,
  Form,
  Upload,
} from "antd"
import moment from 'moment'
import { reevaluationService } from "../../../../services/reevaluation"
import { PaperClipOutlined, CloseOutlined } from '@ant-design/icons'
import { handlers } from './../../../../helpers/handlers'
import { useTranslation } from "react-i18next"
import { Link } from 'gatsby'

const ReevaluationFirstStep = ({
  loading,
  setLoading,
  reevaluationData,
  patientDocuments,
  setPatientDocuments,
  medicalConsultationId
}) => {
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const [canUpload, setCanUpload] = useState(false);

  const formatDate = (date) => {
    return moment(date).format('DD/MM/YYYY')
  }

  const sendPatientFile = (
    info,
    documentItem,
    documentIndex,
    prescriptionKey,
    prescriptionIndex,
    canUpload
  ) => {
    if (canUpload) {
      const { exam_document_id, name } = documentItem
      let fileList = info.fileList

      fileList.forEach((file) => {
        let reader = new FileReader()
        reader.onload = (event) => {
          setLoading(loading => { return { ...loading, sendDocument: true } })

          reevaluationService.sendPatientDocument({
            medical_consultation_id: medicalConsultationId,
            medical_prescription_id: reevaluationData['medical_prescription_id'],
            exam_id: reevaluationData['extra_data'][prescriptionIndex]['examId'],
            document: {
              new_document: documentItem.isNew ? true : false,
              prescription_key: prescriptionKey,
              exam_document_id,
              name,
              file_data: {
                file_name: file.name,
                data: event.target.result,
                file_size: file.size,
                content_type: file.type
              }
            }
          }).then(response => {
            if (response['exam_document_id']) {
              const _patientDocuments = patientDocuments

              _patientDocuments[prescriptionIndex]['documents'][documentIndex] = {
                ..._patientDocuments[prescriptionIndex]['documents'][documentIndex],
                examDocumentId: response['exam_document_id'],
                name,
                fileName: response['file_name'],
                isNew: documentItem.isNew ? true : false
              }

              setPatientDocuments([..._patientDocuments])
              message.success({
                content: 'Archivo cargado con éxito.',
                style: {
                  marginTop: '30vh'
                }
              })
            } else {
              message.error({
                content: 'Ha ocurrido un error al cargar el archivo. Inténtalo nuevamente',
                style: {
                  marginTop: '35vh'
                }
              })
            }
          }).catch(error => {
            console.error('Error interno:', error)
            message.error({
              content: `Error interno: ${error}. Inténtalo nuevamente`,
              style: {
                marginTop: '35vh'
              }
            })
          }).finally(() => {
            setLoading(loading => { return { ...loading, sendDocument: false } })
          })
          file["base64"] = event.target.result
        }
        reader.readAsDataURL(file.originFileObj)
      })
    }
  }

  const downloadPatientFile = (examDocumentId, fileName) => {
    setLoading(loading => { return { ...loading, downloadPatientFile: true } })
    reevaluationService
      .getAsset({ id: examDocumentId, type: 'ExamsDocument', field: 'document' })
      .then(response => {
        handlers.urlDownloadAndOpen(response.document_url, fileName);
        message.success({
          content: 'Prescripción descargada exitosamente',
          style: {
            marginTop: '40vh'
          }
        })
      }).catch(err => {
        console.error('Error interno:', err)
        message.error({
          content: `Error interno: ${err}. Inténtalo nuevamente`,
          style: {
            marginTop: '40vh'
          }
        })
      }).finally(() => {
        setLoading(loading => { return { ...loading, downloadPatientFile: false } })
      })
  }


  const handleRemoveDocument = (
    documentIndex,
    examDocumentId,
    documentName,
    prescriptionKey,
    prescriptionIndex,
    fileName,
    isNew,
    deleteNewDocument
  ) => {
    const _patientDocuments = patientDocuments

    if (deleteNewDocument && deleteNewDocument !== null) {
      if (_patientDocuments[prescriptionIndex]['documents'][documentIndex]['fileName']) {
        deleteDocument(
          documentIndex,
          examDocumentId,
          documentName,
          prescriptionKey,
          prescriptionIndex,
          fileName,
          isNew,
          deleteNewDocument
        )
      } else {
        _patientDocuments[prescriptionIndex].documents.splice(documentIndex, 1)
        setPatientDocuments([..._patientDocuments])
      }
    } else {

      deleteDocument(
        documentIndex,
        examDocumentId,
        documentName,
        prescriptionKey,
        prescriptionIndex,
        fileName,
        isNew,
        deleteNewDocument
      )
    }
  }


  const deleteDocument = async (
    documentIndex,
    examDocumentId,
    documentName,
    prescriptionKey,
    prescriptionIndex,
    fileName,
    isNew,
    deleteNewDocument
  ) => {
    try {
      setLoading(loading => { return { ...loading, deleteDocument: true } })

      const params = {
        exam_document_id: examDocumentId,
        prescription_key: prescriptionKey,
        name: documentName,
        new_document: isNew,
        medical_consultation_id: medicalConsultationId,
        medical_prescription_id: reevaluationData['medical_prescription_id']
      }

      const response = await reevaluationService.deletePatientDocument(params)

      if (response.state === 'success') {
        const _patientDocuments = patientDocuments

        if (deleteNewDocument) {
          _patientDocuments[prescriptionIndex].documents.splice(documentIndex, 1)
        } else {
          _patientDocuments[prescriptionIndex]['documents'][documentIndex] = {
            examsDocumentId: null,
            name: documentName,
            isNew
          }
        }

        setPatientDocuments([..._patientDocuments])
        message.success({
          content: 'Archivo eliminado.',
          style: {
            marginTop: '30vh'
          }
        })
      } else {
        message.error({
          content: `Ocurrió un error al eliminar el documento. Inténtalo nuevamente`,
          style: {
            marginTop: '35vh'
          }
        })
      }
    } catch (error) {
      console.error('Error interno:', error)
      message.error({
        content: `Error interno: ${error}. Inténtalo nuevamente`,
        style: {
          marginTop: '35vh'
        }
      })
    } finally {
      setLoading(loading => { return { ...loading, deleteDocument: false } })
    }
  }

  const addNewDocument = (itemKey) => {
    const _patientDocuments = patientDocuments
    let documentsQuantity = 0

    patientDocuments.forEach(item => {
      if (item.key === itemKey) {
        item.documents.forEach(elem => {
          if (elem) {
            documentsQuantity += 1
          }
        })
      }
    })

    _patientDocuments.forEach(item => {
      if (item.key === itemKey) {
        item.documents.push({
          examDocumentId: null,
          name: `Nuevo documento ${documentsQuantity + 1}`,
          isNew: true
        })
      }
    })

    setPatientDocuments([..._patientDocuments])
  }

  const beforeUpload = (file) => {
    if (file) {
      const isJpgOrPngOrPdf = file.type === 'image/jpeg'
        || file.type === 'image/png'
        || file.type === 'application/pdf';

      if (!isJpgOrPngOrPdf) {
        message.error('Solo puedes subir archivos JPG/PNG/PDF.');
      }

      const isLow6MB = file.size / 1024 / 1024 < 6;
      if (!isLow6MB) {
        message.error({
          content: 'El documento que intenta subir pesa más de 6MB.',
          style: {
            marginTop: '40vh'
          }
        })
      }
      setCanUpload(isLow6MB && isJpgOrPngOrPdf);
      return isLow6MB && isJpgOrPngOrPdf;
    }
  }

  const onFinish = () => {
    setLoading(loading => { return { ...loading, sendFakeDocument: true } })
    let isDocumentAdded = false

    patientDocuments.forEach(item => {
      item.documents.forEach(elem => {
        if (elem['fileName']) {
          isDocumentAdded = true
        }
      })
    })

    setTimeout(() => {
      if (isDocumentAdded) {
        message.success({
          content: 'Documentos enviados exitosamente',
          style: {
            marginTop: '40vh'
          }
        })
      } else {
        message.info({
          content: 'Debes cargar al menos 1 documento para enviar',
          style: {
            marginTop: '40vh'
          }
        })
      }
      setLoading(loading => { return { ...loading, sendFakeDocument: false } })
    }, 2500)
  }


  return (
    <Row justify="center">
      <Col lg={24} xs={24} className="header-wrapper pt-20 pb-20">
        <h2 className="text-center mb-5">{t('patientReevaluation.stepsInfo.stepOneTitle')}</h2>
        {
          patientDocuments ?
            <p className="text-center mb-0">{t('patientReevaluation.stepsInfo.documentText')}</p>
            :
            <p className="text-center mb-0">{t('patientReevaluation.stepsInfo.branchOfficeText')}</p>
        }
      </Col>
      <Col lg={24} xs={24} className="body-wrapper mt-40">
        {
          patientDocuments ?
            <Row justify="center">
              <Col xxl={9} xl={11} lg={10} xs={22}>
                <Row justify="center" gutter={[0, 15]}>
                  <Col lg={24} xs={24} className="mt-5">
                    <p className="text-instruction text-center mt-20"><strong>{t('patientReevaluation.stepsInfo.uploadDocumentsText')}</strong></p>
                    <h4 className="carousel-title mt-10 mb-30 text-center">
                      <b>
                        El tamaño de los archivos no debe exceder los 6MB.
                      </b>
                    </h4>
                    <Row justify="center" gutter={[0, 10]}>
                      <Col lg={24} xs={24}>
                        <Form
                          form={form}
                          onFinish={onFinish}
                          size="large"
                        >
                          {
                            patientDocuments.map((item, index) => {
                              return (
                                <Row justify="center mb-20" key={item.key} gutter={[0, 15]}>
                                  <Col lg={24} xs={24}>
                                    <p className="bold mb-0">{item.name}</p>
                                  </Col>
                                  {
                                    item.documents.map((elem, inx) => {
                                      return (
                                        <Col lg={24} xs={24} className="gray-item pl-40 pr-40 pt-20 pb-20" key={elem.examDocumentId}>
                                          <Row justify="center">
                                            <Col lg={19} xs={24}>
                                              {
                                                elem.isNew &&
                                                <Row justify="end">
                                                  <Col>
                                                    <Button
                                                      onClick={
                                                        () => handleRemoveDocument(
                                                          inx,
                                                          elem.examDocumentId,
                                                          elem.name,
                                                          item.key,
                                                          index,
                                                          elem.fileName,
                                                          elem.isNew,
                                                          elem.isNew ? elem.isNew : elem.isNew === true ? 'deleteNewDocument' : false
                                                        )
                                                      }
                                                      disabled={loading.deleteDocument || loading.sendDocument}
                                                      className="cmt-button secondary non-outline floating p-0"
                                                      type="primary"
                                                      style={{ boxShadow: 'none' }}
                                                    >
                                                      <CloseOutlined style={{ fontSize: 20, color: '#5B356D' }} />
                                                    </Button>
                                                  </Col>
                                                </Row>
                                              }
                                              <Row>
                                                <p className="mb-10">{elem.name}</p>
                                              </Row>
                                              <Row gutter={15} align="middle">
                                                <Col lg={22} xs={22}>
                                                  {
                                                    elem.fileName ?
                                                      <Button type="text" loading={loading.downloadPatientFile} onClick={
                                                        () => downloadPatientFile(
                                                          elem.examDocumentId,
                                                          elem.fileName
                                                        )
                                                      }
                                                        className="file-text mb-0" style={{ "cursor": "pointer" }}>
                                                        {elem.fileName}
                                                      </Button>
                                                      :
                                                      <Form.Item
                                                        name={elem.name}
                                                        getValueFromEvent={
                                                          (info) => sendPatientFile(
                                                            info,
                                                            elem,
                                                            inx,
                                                            item.key,
                                                            index,
                                                            canUpload
                                                          )
                                                        }
                                                        className="mb-0"
                                                      >
                                                        <Upload
                                                          name="logo"
                                                          customRequest={() => console.log("")}
                                                          listType="picture"
                                                          beforeUpload={beforeUpload}
                                                        >
                                                          <Button
                                                            loading={loading.sendDocument}
                                                            className="cmt-button secondary outline"
                                                            type="primary"
                                                            block
                                                          >
                                                            <PaperClipOutlined className="mr-lg-10" style={{ color: '#5B356D', fontSize: 20 }} />
                                                            Cargar documento aquí
                                                          </Button>
                                                        </Upload>
                                                      </Form.Item>
                                                  }
                                                </Col>
                                                <Col lg={2} xs={2}>
                                                  <Button
                                                    loading={loading.deleteDocument}
                                                    onClick={
                                                      () => handleRemoveDocument(
                                                        inx,
                                                        elem.examDocumentId,
                                                        elem.name,
                                                        item.key,
                                                        index,
                                                        elem.fileName,
                                                        elem.isNew,
                                                        elem.isNew ? elem.isNew : elem.isNew === true ? 'deleteNewDocument' : false
                                                      )
                                                    }
                                                    disabled={!elem.fileName}
                                                    className="cmt-button secondary non-outline p-0"
                                                    type="primary"
                                                    style={{ boxShadow: 'none' }}
                                                  >
                                                    {
                                                      !loading.deleteDocument &&
                                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 148.61 189.36"><g id="Capa_2" dataname="Capa 2"><g id="Capa_1-2" dataname="Capa 1"><path d="M47.16,21.25c0-3.94-.14-7.24,0-10.53C47.49,4.64,51.6.29,57.66.17q16.5-.33,33,0c6.37.11,10.48,4.42,10.78,10.79.15,3.28,0,6.58,0,10.4,11.53,0,22.48-.06,33.43,0,7.49,0,12.84,3.93,13.53,10a92.45,92.45,0,0,1,.07,10.16H.13c0-3.65-.34-7.14.08-10.54.69-5.58,5.89-9.6,12.18-9.65C23.71,21.19,35,21.25,47.16,21.25Zm46.89-.2c0-3.38,0-6.19,0-9C94,9,92.36,7.48,89.32,7.48c-10,0-20-.16-29.93.17-1.52,0-3.93,2.14-4.25,3.64-.67,3.1-.2,6.43-.2,9.76Z" /><path d="M15.42,54.5v4.63q0,58.15,0,116.31c0,10.25,4.24,13.91,16.09,13.91q42.55,0,85.09,0c12.36,0,16.25-3.36,16.25-14.11q0-57.93,0-115.88V54.5Z" /></g></g></svg>
                                                    }
                                                  </Button>
                                                </Col>
                                              </Row>
                                              <Row>
                                                <p className="small mt-5 mb-0">{t('patientReevaluation.stepsInfo.fileTypesText')}</p>
                                              </Row>
                                            </Col>
                                          </Row>
                                        </Col>
                                      )
                                    })
                                  }
                                  <Col>
                                    <Button
                                      onClick={() => addNewDocument(item.key)}
                                      className="cmt-button secondary"
                                      type="primary"
                                      size="middle"
                                    >
                                      <i className="icon-system-plus mr-5" />
                                      Agregar otro documento
                                    </Button>
                                  </Col>
                                </Row>
                              )
                            })
                          }
                          <Row justify="center" className="mt-70" gutter={[0, 10]}>
                            <Col lg={24} xs={24}>
                              <p className="text-instruction text-center">{t('patientReevaluation.stepsInfo.solutionText')}</p>
                            </Col>
                            <Col xxl={10} xl={11} lg={13} xs={24}>
                              <Button
                                loading={loading.sendFakeDocument}
                                htmlType="submit"
                                className="cmt-button primary"
                                type="primary"
                              >
                                Enviar documentos
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            :
            <Row justify="center">
              <Col xxl={10} xl={12} lg={10} xs={22} className="branch-office-wrapper">
                <p className="bold text-center">{t('patientReevaluation.stepsInfo.presenceInfoText1')} {formatDate(reevaluationData['final_date'])} {t('patientReevaluation.stepsInfo.presenceInfoText2')}</p>
                <p className="text-center mb-30">{t('patientReevaluation.stepsInfo.reminderInfo1')} <strong>{reevaluationData['reservation_number']}</strong> y <strong>{t('patientReevaluation.stepsInfo.reminderInfo2')}</strong></p>
                <Row justify="center" align="middle" className="mb-10">
                  <Col>
                    <i className="icon-system-placeholder mr-5" />
                  </Col>
                  <Col>
                    <p className="bold primary mt-5 mb-0">{t('patientReevaluation.stepsInfo.yourBranchOfficeText')} {reevaluationData['branch_office_name']}</p>
                  </Col>
                </Row>
                <p className="text-center primary small">{t('patientReevaluation.stepsInfo.openingHoursText')}</p>
                <Row justify="center">
                  <Col lg={24} xs={24}>
                    <p className="text-center small mb-5"><strong>{reevaluationData['branch_office_name']}</strong></p>
                  </Col>
                  <Col lg={24} xs={24}>
                    <p className="text-center small mb-5">{reevaluationData['branch_office_address']}</p>
                  </Col>
                  <Col lg={24} xs={24}>
                    <p className="text-center small mb-5">{reevaluationData['branch_office_phone']}</p>
                  </Col>
                  <Col lg={24} xs={24} className="mt-30">
                    <Link
                      to={`/${t('navbar.branchOffices').toLocaleLowerCase()}/`}
                    >
                      <Button
                        className="cmt-button primary non-outline"
                        type="primary"
                        style={{ boxShadow: 'none', fontSize: 14 }}
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                      >
                        <i className="icon-system-plus mr-5" />
                        {t('navbar.branchOffices').toUpperCase()}
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
        }
      </Col>
    </Row>
  )
}

export default ReevaluationFirstStep;