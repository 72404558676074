import config from "../config";

const getPatientPrescriptionDetail = async (params) => {
  const url = new URL(`${config.client_api}/reevaluation/details`);

  if (params) {
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    )
  }

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const dataResponse = response.json();
  return dataResponse;
};

const getIsReevaluationFinished = async (params) => {
  const url = new URL(`${config.client_api}/reevaluation/checkFinishedReevaluation/${params.medicalConsultationId}`);

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const dataResponse = response.json();
  return dataResponse;
};

const getPrescriptionAsset = async (params) => {
  const url = new URL(`${config.client_api}/87164e4d-1fd7-478b-a4be-6358e56ea622`)

  if (params) {
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    )
  }

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const dataResponse = response.json();
  return dataResponse;
};

const sendPatientDocument = async (params) => {
  const url = new URL(`${config.client_api}/reevaluation/uploadReevaluationFile`);

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });

  const dataResponse = response.json();
  return dataResponse;
};

const deletePatientDocument = async (params) => {
  const url = new URL(`${config.client_api}/reevaluation/removeReevaluationFile`);

  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });

  return response.json();
};

const getPatientSteps = async (params) => {
  const url = new URL(`${config.client_api}/reevaluation/details`);

  if (params) {
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    )
  }

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const dataResponse = response.json();
  return dataResponse;
};

const getAsset = async (params) => {
  const url = new URL(`${config.client_api}/assets/getAsset`)

  if (params) {
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    )
  }

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const dataResponse = response.json();
  return dataResponse;
};

export const reevaluationService = {
  getPatientPrescriptionDetail,
  getPrescriptionAsset,
  sendPatientDocument,
  deletePatientDocument,
  getPatientSteps,
  getIsReevaluationFinished,
  getAsset,
};
